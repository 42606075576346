<template>
  <v-container id="client" fluid tag="section" style="height: calc(100% - 90px);">
    <v-col cols="12">
      <v-btn color="default" class="mr-0" @click="back()">
        {{ str['back'] }}
      </v-btn>
    </v-col>
    <template>
      <v-tabs v-model="tab" background-color="transparent" color="secondary" style="margin-top: 0;" grow show-arrows>
        <v-tab v-for="tabItem in tabs" :key="tabItem.id">
          {{ tabItem.title }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" style="min-height: calc(100% - 115px);">
        <v-tab-item v-for="tabItem in tabs" :key="tabItem.id">
          <v-card v-if="tabs[tab].id === 'details'" color="basil" style="margin-top: 0;" flat>
            <v-card-text v-if="client">
              <v-list-item>
                <v-list-item-content style="margin: 0 auto; max-width: 400px;">
                  <v-list-item-title v-for="(detail) in details" :key="detail.id" cols="12"
                    style="margin: 5px 0; display: flex;">
                    <span class="font-weight-black" style="margin-right: 5px;">
                      {{ detail.title }}:
                    </span>
                    <span class="font-weight-regular">
                      {{ detail.value ? detail.value : client[detail.id] }}
                    </span>
                    <v-icon v-if="detail.edit" style="margin-left: 3px; transform: translateY(-1px);"
                      @click="openEditDetails(detail)" color="warning" class="cursor-hover">
                      mdi-pencil-box
                    </v-icon>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-col v-if="client.payment_status === 0" cols="12 text-center" class="pt-3">
                <v-btn color="success" class="mr-0" style="min-width: 250px;" @click="confirmPayment">
                  {{ str['update_payment'] }}
                </v-btn>
              </v-col>
            </v-card-text>
          </v-card>

          <v-card v-if="tabs[tab].id === 'payments'" color="basil" style="margin-top: 0;" flat>
            <v-data-table :headers="paymentHeaders" :items="payments" :no-data-text="str['payments_empty']"
              class="elevation-1">
              <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
              </template>
            </v-data-table>
          </v-card>

          <v-card v-if="tabs[tab].id === 'blocked_clients'" color="basil" style="margin-top: 0;" flat>
            <v-data-table :headers="blockedClientsHeaders" :items="blockedClients"
              :search="search" :no-data-text="str['clients_empty']" class="elevation-1"
              @click:row="openClientData">
              <template v-slot:top>
                <v-toolbar flat>
                  <v-text-field v-model="search" append-icon="mdi-magnify" :label="str['search']" single-line hide-details />
                  <v-spacer />
                </v-toolbar>
              </template>
              <template v-slot:item.email="{ item }">
                <span class="text-ellipsis-large">
                  {{ item.email }}
                </span>
              </template>
              <template v-slot:item.mobile_phone="{ item }">
                <span class="text-ellipsis">
                  {{ item.mobile_phone }}
                </span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" color="secondary"
                  @click.stop="openPTClient(item)">
                  mdi-eye
                </v-icon>
                <v-icon small class="mr-2" color="success"
                  @click.stop="unblockPTClient(item)">
                  mdi-account-check
                </v-icon>
                <v-icon small color="error"
                  @click.stop="deletePTClient(item)">
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
              </template>
            </v-data-table>
          </v-card>

          <v-card v-if="tabs[tab].id === 'refused_registers'" color="basil" style="margin-top: 0;" flat>
            <v-data-table :headers="refusedRegistersHeaders" :items="refusedRegisters" :search="search" :no-data-text="str['clients_empty']"
              class="elevation-1">
              <template v-slot:top>
                <v-toolbar flat>
                  <v-text-field v-model="search" append-icon="mdi-magnify" :label="str['search']" single-line hide-details />
                  <v-spacer />
                </v-toolbar>
              </template>
              <template v-slot:item.email="{ item }">
                <span class="text-ellipsis-large">
                  {{ item.email }}
                </span>
              </template>
              <template v-slot:item.mobile_phone="{ item }">
                <span class="text-ellipsis">
                  {{ item.mobile_phone }}
                </span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" color="success"
                  @click.stop="activateRegister(item)">
                  mdi-account-check
                </v-icon>
              </template>
              <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <v-dialog v-model="dialogDetail" persistent max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">
              {{ str['edit_data'] }}
            </span>
          </v-card-title>
          <v-card-text v-if="detailToEdit">
            <v-container>
              <v-row>
                <v-text-field v-if="detailToEdit.field.type === 'input' && detailToEdit.id != 'email'"
                  v-model="detailToEdit.value" v-bind:label="detailToEdit.title" />
                <v-text-field v-if="detailToEdit.field.type === 'input' && detailToEdit.id === 'email'"
                  v-model="detailToEdit.value" v-bind:label="detailToEdit.title"
                  @input="detailToEdit.value = detailToEdit.value.toLowerCase()" />
                <v-autocomplete v-if="detailToEdit.field.type === 'select'" v-bind:label="detailToEdit.title"
                  item-text="label" item-value="value" v-model="detailToEdit.value" :items="detailToEdit.field.items" />
                <v-menu v-if="detailToEdit.field.type === 'datepicker'" v-model="datepickerDetailToEditMenu"
                  :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
                  min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="detailToEdit.value" v-bind:label="detailToEdit.title" readonly v-bind="attrs"
                      v-on="on" />
                  </template>
                  <v-date-picker v-model="detailToEdit.value" style="margin: 0;"
                    @change="datepickerDetailToEditMenu = false" />
                </v-menu>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="default" @click="cancelEditDetails">
              {{ str['cancel'] }}
            </v-btn>
            <v-btn color="success" @click="saveEditDetails">
              {{ str['save'] }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogClient" persistent max-width="650px">
        <v-card>
          <v-card-title>
            <span class="headline">
              {{ str['edit_data'] }}
            </span>
          </v-card-title>
          <v-card-text v-if="clientData && clientDataOpened">
            <v-container>
              <v-row>
                <v-col
                  v-for="item in clientData"
                  :key="item.id"
                  cols="12"
                >
                  <v-text-field
                    v-if="item.type === 'input'"
                    v-model="item.value"
                    :label="item.title"
                  />
                  <v-textarea
                    v-if="item.type === 'textarea'"
                    v-model="item.value"
                    :label="item.title"
                    hide-details
                  />
                  <v-autocomplete
                    v-if="item.type === 'select'"
                    v-model="item.value"
                    :label="item.title"
                    item-text="label"
                    item-value="value"
                    :items="item.items"
                    :multiple="item.multiple"
                    :no-data-text="str['no_data']"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="default" @click="cancelClientData">
              {{ str['cancel'] }}
            </v-btn>
            <v-btn color="warning" @click="emptyClientTags(clientDataOpened)">
              {{ str['empty_tags'] }}
            </v-btn>
            <v-btn color="success" @click="saveClientData">
              {{ str['save'] }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogUserInfo" persistent max-width="650px">
        <v-card>
          <v-card-title>
            <span class="headline">
              {{ str['details'] }}
            </span>
          </v-card-title>
          <v-card-text v-if="userInfo">
            <v-container>
              <v-row>
                <div v-html="userInfo" style="color: #fff; overflow: hidden;"></div>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="default" @click="dialogUserInfo = false">
              {{ str['close'] }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-container>
</template>
<script>
import Api from '../../../services/Api'
import Utils from '../../../services/Utils'

export default {
  data() {
    return {
      str: window.strings,
      search: '',
      clientId: parseFloat(sessionStorage.getItem('pt-id')),
      client: null,
      editClient: null,
      tabs: [{
        id: 'details',
        title: window.strings['general_details'],
      }, {
        id: 'payments',
        title: window.strings['payments'],
      }, {
        id: 'blocked_clients',
        title: window.strings['blocked_clients'],
      }, {
        id: 'refused_registers',
        title: window.strings['refused_registers'],
      }],
      tab: 0,
      dialogDetail: false,
      detailToEdit: null,
      details: [{
        id: 'id',
        title: window.strings['id'],
      }, {
        id: 'name',
        title: window.strings['name'],
        edit: {
          type: 'input'
        },
      }, {
        id: 'email',
        title: window.strings['email'],
        edit: {
          type: 'input'
        },
      }, {
        id: 'password',
        title: window.strings['password'],
        edit: {
          type: 'input'
        },
      }, {
        id: 'birth_date',
        title: window.strings['birth_date'],
        edit: {
          type: 'datepicker'
        }
      }, {
        id: 'mobile_phone',
        title: window.strings['mobile_phone'],
        edit: {
          type: 'input'
        },
      }, {
        id: 'clients',
        title: window.strings['clients'],
      }, {
        id: 'payment_amount',
        title: window.strings['monthly_payment'],
      }],
      paymentHeaders: [
        //{ text: window.strings['id'], value: 'id', width: 50 },
        { text: window.strings['year'], value: 'year', align: 'center', width: 60 },
        { text: window.strings['month'], value: 'month', align: 'center', width: 60 },
        { text: window.strings['registration_created_date'], value: 'dt_create', align: 'center', width: 120 },
        { text: window.strings['registration_validated_date'], value: 'dt_update', align: 'center', width: 120 },
        { text: window.strings['clients'], value: 'clients', align: 'center', width: 80 },
        { text: window.strings['monthly_payment'], value: 'amount', align: 'center', width: 80 },
        { text: window.strings['payment'], value: 'status', align: 'center', width: 100 },
      ],
      payments: [],
      blockedClientsHeaders: [
        { text: window.strings['id'], value: 'id', width: 50 },
        { text: window.strings['name'], value: 'name', align: 'center', width: 120 },
        { text: window.strings['email'], value: 'email', align: 'center', width: 120 },
        { text: window.strings['mobile_phone'], value: 'mobile_phone', align: 'center', width: 120 },
        { text: window.strings['tags'], value: 'tags', align: 'center', width: 120 },
        { text: window.strings['actions'], value: 'actions', sortable: false, align: 'center', width: 120 },
      ],
      blockedClients: [],
      refusedRegistersHeaders: [
        { text: window.strings['id'], value: 'id', width: 50 },
        { text: window.strings['name'], value: 'name', align: 'center', width: 120 },
        { text: window.strings['email'], value: 'email', align: 'center', width: 120 },
        { text: window.strings['mobile_phone'], value: 'mobile_phone', align: 'center', width: 120 },
        { text: window.strings['actions'], value: 'actions', sortable: false, align: 'center', width: 120 },
      ],
      refusedRegisters: [],
      clientDataOpened: null,
      dialogClient: false,
      clientData: [{
        type: 'input',
        id: 'name',
        title: window.strings['name'],
        value: '',
      }, {
        type: 'input',
        id: 'email',
        title: window.strings['email'],
        value: '',
      }, {
        type: 'input',
        id: 'mobile_phone',
        title: window.strings['mobile_phone'],
        value: '',
      }],
      dialogUserInfo: false,
      userInfo: null,
    }
  },
  beforeMount: function () {
    this.$isLoading(true)
    this.getClient()
  },
  methods: {
    back: function () {
      this.$router.back()
    },
    getClient: function () {
      const self = this
      Api.getClient(this.clientId, {
        id: this.clientId
      }, function (response) {
        if (response.success) {
          self.getClientCurrentPayment(response.data[0])
        } else {
          self.$isLoading(false)
          self.$alert(
            response.message,
            '',
            'warning',
            Utils.getAlertOptions()
          )
        }
      })
    },
    getClientCurrentPayment: function (client) {
      const self = this
      Api.getClientLastPayment(this.clientId, client.id, function (response) {
        if (response.success) {
          client.clients = response.data[0] ? response.data[0].clients : 0
          client.payment_amount = response.data[0] ? response.data[0].amount : 0
          client.payment_status = response.data[0] ? response.data[0].status : 0
          self.decryptPassword(client)
          self.getClientPayments(client)
          self.getPTBlockedClients(client.pt_id)
          self.getPTRefusedRegisters(client.pt_id)
        } else {
          self.$isLoading(false)
          self.$alert(
            response.message,
            '',
            'warning',
            Utils.getAlertOptions()
          )
        }
      })
    },
    getClientPayments: function (client) {
      const self = this
      Api.getClientPayments(this.clientId, client.id, function (response) {
        if (response.success) {
          const payments = []
          response.data.forEach(function (item, index) {
            item.id = index + 1
            payments.push(item)
          })
          payments.sort((a, b) => new Date(b.dt_create) - new Date(a.dt_create))
          self.payments = payments
        } else {
          self.$alert(
            response.message,
            '',
            'warning',
            Utils.getAlertOptions()
          )
        }
      })
    },
    getPTBlockedClients: function (ptId) {
      const self = this
      Api.getPTBlockedClients(ptId, '"id,pt_id,name,email,mobile_phone,tags"', function (response) {
        if (response.success) {
          self.blockedClients = response.data
        } else {
          self.$alert(
            response.message,
            '',
            'warning',
            Utils.getAlertOptions()
          )
        }
      })
    },
    getPTRefusedRegisters: function (ptId) {
      const self = this
      const data = {
        ptId: ptId,
        status: [2,3],
        fields: ['id','pt_id','name','email','mobile_phone'],
      }
      Api.getPTRegisters(this.clientId, data, function (response) {
        if (response.success) {
          self.refusedRegisters = response.data
        } else {
          self.$alert(
            response.message,
            '',
            'warning',
            Utils.getAlertOptions()
          )
        }
      })
    },
    decryptPassword: function (client) {
      const self = this
      Api.decryptPassword(this.clientId, client.password, function (response) {
        self.$isLoading(false)
        if (response.success) {
          for (let i = 0; i < self.details.length; i++) {
            if (self.details[i].id === 'password') {
              self.details[i].value = response.data.password
              break
            }
          }
          self.client = client
        } else {
          self.$alert(
            response.message,
            '',
            'warning',
            Utils.getAlertOptions()
          )
        }
      })
    },
    openEditDetails(detail, inputClient) {
      this.editClient = inputClient ? inputClient : this.client
      this.detailToEdit = {
        id: detail.id,
        title: detail.title,
        value: detail.value ? detail.value : this.editClient[detail.id],
        field: detail.edit
      }
      this.dialogDetail = true
    },
    cancelEditDetails() {
      this.dialogDetail = false
      this.detailToEdit = null
    },
    saveEditDetails() {
      const self = this

      if (!this.detailToEdit) {
        return false
      }

      const data = {
        id: this.editClient.id,
        pt_id: this.editClient.pt_id,
      }
      if (this.detailToEdit.id === 'email') {
        data[this.detailToEdit.id] = this.detailToEdit.value.toLowerCase().trim()
      } else {
        data[this.detailToEdit.id] = this.detailToEdit.value
      }

      this.$isLoading(true)
      Api.updateUser(this.clientId, data, function (response) {
        if (response.success) {
          self.cancelEditDetails()
          if (self.editClient.id < 0) {
            // Só vai buscar os dados se for um PT
            self.getClient()
          } else {
            self.$isLoading(false)
          }
        } else {
          self.$isLoading(false)
          self.$alert(
            response.message,
            '',
            'warning',
            Utils.getAlertOptions()
          )
        }
      })
    },
    confirmPayment() {
      const self = this
      this.$isLoading(true)
      Api.updateClientPayment(this.clientId, {
        id: this.client.id,
        status: 1
      }, function (response) {
        if (response.success) {
          self.getClient()
        } else {
          self.$isLoading(false)
          self.$alert(
            response.message,
            '',
            'warning',
            Utils.getAlertOptions()
          )
        }
      })
    },
    deletePTClient(ptClient) {
      const self = this
      this.$confirm(
        window.strings['are_you_sure_delete'],
        '',
        'warning',
        Utils.getAlertOptions(true, true)
      ).then(() => {
        self.$isLoading(true)
        Api.deleteUser(self.clientId, {
          id: ptClient.id,
          pt_id: ptClient.pt_id
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.getPTBlockedClients(ptClient.pt_id)
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      }).catch(() => {})
    },
    unblockPTClient(ptClient) {
      const self = this
      this.$isLoading(true)
      Api.updateUser(this.clientId, {
        id: ptClient.id,
        pt_id: ptClient.pt_id,
        status: 0,
        email: Utils.extractEmail(ptClient.email)
      }, function (response) {
        self.$isLoading(false)
        if (response.success) {
          self.getPTBlockedClients(response.data.pt_id)
        } else {
          self.$alert(
            response.message,
            '',
            'warning',
            Utils.getAlertOptions()
          )
        }
      })
    },
    activateRegister(ptRegister) {
      const self = this
      this.$isLoading(true)
      Api.updateRegister(this.clientId, {
        id: ptRegister.id,
        pt_id: ptRegister.pt_id,
        status: 0,
        email: Utils.extractEmail(ptRegister.email)
      }, function (response) {
        self.$isLoading(false)
        if (response.success) {
          self.getPTRefusedRegisters(response.data.pt_id)
        } else {
          self.$alert(
            response.message,
            '',
            'warning',
            Utils.getAlertOptions()
          )
        }
      })
    },
    openClientData(client) {
      for (let i = 0; i < this.clientData.length; i++) {
        this.clientData[i].value = client[this.clientData[i].id]
      }
      this.clientDataOpened = client
      this.dialogClient = true
    },
    saveClientData() {
      const self = this
      const obj = {
        id: this.clientDataOpened.id,
        pt_id: this.clientDataOpened.pt_id
      }
      for (let i = 0; i < this.clientData.length; i++) {
        obj[this.clientData[i].id] = this.clientData[i].value
      }
      this.$isLoading(true)
      Api.updateUser(this.clientId, obj, function (response) {
        self.$isLoading(false)
        if (response.success) {
          self.getPTBlockedClients(obj.pt_id)
          self.cancelClientData()
        } else {
          self.$alert(
            response.message,
            '',
            'warning',
            Utils.getAlertOptions()
          )
        }
      })
    },
    cancelClientData() {
      this.dialogClient = false
      this.clientDataOpened = null
    },
    emptyClientTags(ptClient) {
      const self = this
      this.$isLoading(true)
      Api.updateUser(this.clientId, {
        id: ptClient.id,
        pt_id: ptClient.pt_id,
        tags: ''
      }, function (response) {
        self.$isLoading(false)
        if (response.success) {
          self.getPTBlockedClients(response.data.pt_id)
        } else {
          self.$alert(
            response.message,
            '',
            'warning',
            Utils.getAlertOptions()
          )
        }
      })
    },
    openPTClient(ptClient) {
      const self = this
      this.$isLoading(true)
      Api.getUser(this.clientId, {
        id: ptClient.id,
        pt_id: ptClient.pt_id
      }, function (response) {
        self.$isLoading(false)
        if (response.success && response.data && response.data[0]) {
          let info = ''
          for (const key in response.data[0]) {
            info += '<div><b>' + key + ': </b>' + response.data[0][key] + '</div>'
          }
          self.userInfo = info
          self.dialogUserInfo = true
        } else {
          self.$alert(
            response.message,
            '',
            'warning',
            Utils.getAlertOptions()
          )
        }
      })
    },
  }
}

</script>
<style></style>
